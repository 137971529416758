#dining-btn {
  color: #FFF;
  background-color: #898486;
  font-size: .875rem;
  font-weight: bold;
  border: 1px solid black;
}

#dining-btn:hover {
  background-color: #6e696b;
}